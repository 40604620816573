<template>
  <v-container
    id="fix-shift-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="pr-10 pl-10 mt-4">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        item-key="name"
        stle
        :loading-text="$t('table.messages.loading')"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 100],
          showFirstLastPage: false,
          'items-per-page-text': $t('table.messages.items_per_page'),
        }"
        :no-data-text="$t('table.messages.no_data')"
      >
        <template v-slot:top>
          <v-container class="my-5">
            <v-row class="infoWarp">
              <v-col cols="4">
                <v-row>
                  <v-col cols="2">
                    <div class="lblText">
                      <h3>{{ $t("messages.job") }}</h3>
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <div class="align-self-center mr-9">
                      <v-select
                        v-model="itemJob"
                        item-text="name"
                        item-value="id"
                        :items="listJobs"
                        name="jobId"
                        outlined
                        :no-data-text="$t('table.messages.no_data')"
                        :placeholder="getSelectorPlaceholder"
                        solo
                        @change="changeJob"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
              >
                <div class="lblText">
                  <h3>{{ $t("messages.mode") }}</h3>
                </div>
              </v-col>
              <v-col
                cols="2"
              >
                <v-select
                  v-model="optionChart"
                  :items="itemsType"
                  class="d-flex flex-row-reverse custom-select"
                  outlined
                  @change="onChangeType"
                />
              </v-col>
              <v-col
                v-if="optionChart === $t('chart.weekly')"
                cols="2"
              >
                <v-select
                  v-model="optionYear"
                  :items="itemsyear"
                  item-text="text"
                  item-value="value"
                  class="d-flex flex-row-reverse custom-select"
                  outlined
                  @change="onChangeYear"
                />
              </v-col>
              <v-col
                v-if="optionChart === $t('chart.daily')"
                cols="2"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :return-value.sync="daily"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="daily"
                      :label="$t('chart.timeUnit')"
                      readonly
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="daily"
                    no-title
                    scrollable
                    locale="ja"
                    :max="timestamps[1]"
                    :min="timestamps[0]"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      {{ $t('messages.cancel') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDay(daily)"
                    >
                      {{ $t('messages.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                v-if="optionChart === $t('chart.daily')"
                cols="2"
                class="d-flex align-end p-0"
              >
                <div class="mb-9">
                  <json-excel
                    class="v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--light v-size--x-large accent btn-download"
                    :fetch="exportCsv"
                    :fields="headerExport"
                    :name="exportHeadText"
                  >
                    {{ $t('messages.exportCsv') }}
                  </json-excel>
                </div>
              </v-col>
            </v-row>
            <v-row class="infoWarp">
              <v-col cols="6">
                <v-row>
                  <v-col
                    cols="8"
                    class="text-right d-flex align-center"
                  >
                    <div class="v-chip-shift justify-center">
                      {{ periodShift.name }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="d-flex justify-center">
                  <v-col cols="8">
                    <div class="align-self-center mr-9">
                      <v-text-field
                        id="parttimerManageId"
                        v-model="search"
                        name="search pa-0"
                        outlined
                        clearable
                        :placeholder="$t('placeholder.enterControlNumber')"
                        @change="callApi"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              v-if="warningTimes.length > 0"
              justify="center"
            >
              <v-col
                cols="4"
                class="text-center color_red"
                style="cursor:pointer"
                @click="openPopupError()"
              >
                <div>注意：２８時間オーバーしたアルバイトがありますので</div>
                <div style="font-weight:700">
                  詳細はこちらをクリックしてご確認ください。
                </div>
              </v-col>
              <v-col
                cols="1"
                align-self="center"
              >
                <v-img
                  width="37"
                  height="32"
                  :src="require('@/assets/warnning.jpg')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template
          v-slot:[`footer.page-text`]
          class="mr-0"
        >
          {{ $t('table.messages.page') }}
          {{ options.page }}
        </template>
        <template v-slot:body="{ items }">
          <tbody
            v-if="items.length > 0"
            name="list"
          >
            <tr
              v-for="(item, index) in items"
              :key="index"
              class="item-row"
            >
              <td>
                {{ index +1 }}
              </td>
              <td>
                {{ item.manageId }}
              </td>
              <td>
                {{ item.user ? item.user.name : "" }}
              </td>
              <td>
                {{ item.furiganaName }}
              </td>
              <td
                v-for="i in headerNumber"
                :key="index + item.furiganaName + i"
                class="text-center item_job"
                :class="addClassCurrent(i)"
                @click="openDialog(item, i, index)"
              >
                <div>
                  <div>{{ textJob(item.listFixedShift[headers[i + 3].dateFormat]) }}</div>
                  <div>{{ textShift(item.listFixedShift[headers[i + 3].dateFormat]) }}</div>
                  <div
                    class="color_work"
                  >
                    {{ textWork(item.listFixedShift[headers[i + 3].dateFormat]) }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="100%">
                {{ $t("error.no_data") }}
              </td>
            </tr>
          </tbody>
        </template>
        <template
          v-for="(header,i) in headers"
          v-slot:[`header.${header.value}`]
        >
          <div
            :key="(Math.random() + i).toString(36).substring(7)"
            style="cursor:pointer"
            @click="openDialogGroup(header, i)"
          >
            {{ header.text }}
          </div>
          <hr
            v-if="i >= 4"
            :key="(Math.random() + i).toString(36).substring(7)"
          >
          <div
            v-if="i >= 4"
            :key="(Math.random() + i).toString(36).substring(7)"
          >
            {{ totalHeader[i - 4] }}
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-row
      class="mt-4"
      justify="end"
    >
      <v-col cols="2">
        <v-btn
          large
          block
          color="info"
        >
          <span
            class="text-h5 pr-3 pl-3"
            @click="$router.push('/shift-fixed-list')"
          >
            {{ $t('messages.close') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogError"
      max-width="1080px"
      @click:outside="closePopupError"
    >
      <v-card>
        <v-card-title
          class="font-weight-bold text-h4 color-current-date"
        >
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <div>
                <div class="text-center my-5 color_red">
                  ２８時間オーバーしたアルバイト
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="padding: 40px">
          <v-data-table
            :headers="headersTableError"
            :items="warningTimes"
            :items-per-page="5"
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 20],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
          >
            <template v-slot:[`footer.page-text`]="props">
              {{ totalWarning }}
              {{ $t('table.headers.page-text') }}
              {{ props.pageStart }} - {{ props.pageStop }}
            </template>
            <template slot="no-data">
              {{ $t('table.messages.no_data') }}
            </template>
            <template v-slot:[`item.index`]="props">
              {{ items.indexOf(props.item) + 1 }}
            </template>
            <template v-slot:[`item.name`]="props">
              <a>{{ props.item.name }}</a>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center mb-5">
            <v-col
              cols="2"
            >
              <v-btn
                block
                color="primary"
                @click="closePopupError"
              >
                <span class="text-h5 font-weight-bold">閉じる</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      @click:outside="closePopup"
    >
      <v-card>
        <validation-observer
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <v-card-title
              class="font-weight-bold text-h4 color-current-date"
            >
              <v-row>
                <v-col class="d-flex align-center justify-center">
                  <div>
                    <div class="text-center">
                      シフト変更
                    </div>
                    <div
                      class="text-center"
                    >
                      {{ titleDate }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <div class="borderDialog">
                  <v-row class="align-center">
                    <v-col cols="3">
                      <div class="lblText">
                        <h3>
                          {{ $t("messages.managerId") }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div> {{ itemsRow.manageId }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col cols="3">
                      <div class="lblText">
                        <h3>
                          {{ $t("messages.name") }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div>{{ itemsRow.user.name || '' }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col cols="3">
                      <div class="lblText">
                        <h3>
                          {{ $t("table.headers.furiganaName") }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div>{{ itemsRow.furiganaName || '' }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col cols="3">
                      <div class="lblText">
                        <h3>
                          {{ $t("messages.job") }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <validation-provider
                        v-slot="{ errors }"
                        name="jobPopupId"
                        rules="required"
                      >
                        <v-select
                          v-model="itemJobPopup"
                          item-text="name"
                          item-value="id"
                          :items="listJob"
                          name="jobPopupId"
                          outlined
                          :error-messages="errors[0]"
                          :no-data-text="$t('table.messages.no_data')"
                          :placeholder="getSelectorPlaceholder"
                          @change="choosenJob"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col cols="3">
                      <div class="lblText">
                        <h3>
                          {{ $t("messages.shift") }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <validation-provider
                        v-slot="{ errors }"
                        name="shiftPopupId"
                        rules="required"
                      >
                        <v-select
                          v-model="itemShiftPopup"
                          name="shiftPopupId"
                          :items="itemsShifts"
                          :item-text="s => s.shiftStartAt + ' ~ ' + s.shiftEndAt"
                          item-value="id"
                          outlined
                          :error-messages="errors[0]"
                          :no-data-text="$t('table.messages.no_data')"
                          :placeholder="getSelectorPlaceholder"
                          @change="choosenShift"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-row class="d-flex justify-center mb-3">
                <v-col
                  v-if="isEdit"
                  cols="2"
                >
                  <v-btn
                    block
                    color="red"
                    @click="deteleItemAR(itemsRowSelected)"
                  >
                    <span class="text-h5 font-weight-bold">{{ $t("button.delete") }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="2"
                >
                  <v-btn
                    block
                    color="primary"
                    type="submit"
                  >
                    <span class="text-h5 font-weight-bold">{{ $t("button.save") }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    block
                    color="grey"
                    @click="closePopup"
                  >
                    <span class="text-h5 font-weight-bold white--text">
                      {{
                        $t("messages.close")
                      }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogGroup"
      max-width="800px"
    >
      <v-card>
        <validation-observer
          ref="obValidate"
        >
          <v-card-title
            class="font-weight-bold text-h4 color-current-date"
          >
            <v-row>
              <v-col class="d-flex align-center justify-center">
                <div>
                  <div class="text-center">
                    毎日のシフト情報
                  </div>
                  <div
                    class="text-center"
                  >
                    {{ titleDate }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="borderDialog pb-5">
                <div
                  v-for="(i, index) in itemDayShifts"
                  :key="index"
                >
                  <v-row class="align-center">
                    <v-col cols="1" />
                    <v-col cols="3">
                      <v-chip>
                        <div class="mx-3">
                          {{ i.jobName }}
                        </div>
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      class="d-flex justify-end"
                    >
                      <div>
                        <div class="lblText text-center title-group-job">
                          <h3>
                            シフト
                          </h3>
                        </div>
                        <div
                          v-for="(item, index1) in i.shifts"
                          :key="i.jobName + item.timestamps + index1"
                          class="lblText"
                        >
                          <v-card
                            elevation="8"
                            style="background: rgba(196, 196, 196, 0.09);"
                          >
                            <v-card-text>
                              <div class="item-date">
                                {{ item.timestamps }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="d-flex"
                    >
                      <div>
                        <div class="lblText text-center title-total-user">
                          <div>
                            人数
                          </div>
                        </div>
                        <div
                          v-for="(item, index1) in i.shifts"
                          :key="i.jobName + item.totalUser + index1"
                          class="lblText"
                        >
                          <v-card
                            elevation="8"
                            style="background: rgba(196, 196, 196, 0.09);"
                          >
                            <v-card-text class="mx-1">
                              <div class="px-5 item-total-user">
                                {{ item.totalUser }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row class="d-flex justify-center mb-5">
              <v-col
                cols="2"
              >
                <v-btn
                  block
                  color="primary"
                  @click="closePopup"
                >
                  <span class="text-h5 font-weight-bold">閉じる</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import JsonExcel from 'vue-json-excel'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  export default {
    name: 'FixShiftList',
    components: {
      ValidationProvider,
      ValidationObserver,
      JsonExcel,
    },
    data () {
      return {
        dialog: false,
        dialogGroup: false,
        dialogDelete: false,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        options: {},
        headers: [],
        titleDate: null,
        itemsRowSelected: {},
        itemsRow: {},
        items: [],
        totalHeader: [],
        search: '',
        loading: false,
        totalItems: 1,
        itemsShifts: [],
        headerNumber: 7,
        itemsMode:
          [
            {
              text: '月版',
              value: 'month',
            },
            {
              text: '週版',
              value: 'week',
            },
          ],
        dayInMount: null,
        headersFix: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'font-weight-bold',
            width: '70px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.manageId'),
            align: 'start',
            value: 'manageId',
            class: 'font-weight-bold',
            width: '100px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.name'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            width: '140px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.furiganaName'),
            align: 'start',
            value: 'furiganaName',
            class: 'font-weight-bold',
            width: '190px',
            sortable: false,
          },
        ],
        headersTableError: [
          {
            text: this.$t('table.headers.controlNumber'),
            align: 'start',
            value: 'manageId',
            class: 'font-weight-bold',
            width: '100px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.name'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            width: '120px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.furiganaName'),
            align: 'start',
            value: 'furiganaName',
            class: 'font-weight-bold',
            width: '180px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.time_to_violate'),
            align: 'start',
            value: 'timestamp',
            class: 'font-weight-bold',
            width: '200px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.time_allowed'),
            align: 'start',
            value: 'maxWorkHour',
            class: 'font-weight-bold',
            width: '110px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.commutingTime'),
            align: 'start',
            value: 'totalWorkHour',
            class: 'font-weight-bold',
            width: '110px',
            sortable: false,
          },
          {
            text: this.$t('table.headers.over_work_hour'),
            align: 'start',
            value: 'overWorkHour',
            class: 'font-weight-bold',
            width: '90px',
            sortable: false,
          },
        ],
        startAt: null,
        endAt: null,
        itemsType: [this.$t('chart.monthly'), this.$t('chart.weekly'), this.$t('chart.daily')],
        itemsyear: [],
        optionChart: this.$t('chart.weekly'),
        optionYear: 1,
        endDateNumber: 0,
        listJobs: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        itemJob: null,
        itemJobPopup: null,
        itemShiftPopup: null,
        isEdit: null,
        workDate: null,
        itemDayShifts: [],
        warningTimes: [],
        dialogError: false,
        menu: false,
        daily: null,
        headerExport: {},
        timestamps: [],
      }
    },
    computed: {
      getSelectorPlaceholder: function () {
        return this.$t('messages.all')
      },
      getPlaceholderMode: function () {
        return this.$t('messages.placeholderMode')
      },
      ...get('fixedShift', ['listFixedShift', 'message', 'status', 'error', 'total', 'periodShift', 'itemDayShift', 'warningTime', 'totalWarning']),
      listJob: get('job@list'),
      customerDetail: get('customer@customerDetail'),
      exportHeadText () {
        return 'report_' + moment(this.daily).format('YYYY_MM_DD_hh_ss')
      },
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      options: {
        handler () {
          if (this.pageOld !== 0) {
            if (this.options.page > this.pageOld && this.options.page !== 1) {
              this.totalStt += this.items.length
            } else if (this.options.page < this.pageOld && this.options.page !== 1) {
              this.totalStt -= this.options.itemsPerPage
            } else {
              this.totalStt = 0
            }
          }
          this.pageOld = this.options.page
        },
        deep: true,
      },
      status (value) {
        this.loading = value === 'loading'
      },
      totalCount (value) {
        this.totalItems = value
      },
      listFixedShift (value) {
        this.$set(this, 'items', value)
      },
      listJob (value) {
        value.forEach(item => {
          this.listJobs.push(item)
        })
      },
      optionChart () {
        this.headers = []
        const titleHeaders = this.getHeaderTitle()
        this.headers = [...this.headersFix, ...titleHeaders]
      },
      optionYear () {
        this.headers = []
        const titleHeaders = this.getHeaderTitle()
        this.headers = [...this.headersFix, ...titleHeaders]
      },
      total (value) {
        this.totalHeader = value
      },
      warningTime (value) {
        this.$set(this, 'warningTimes', value)
      },
      itemJobPopup (value) {
        const data = this.customerDetail.jobs.find((i) => i.id === value)
        this.itemsShifts = data && data.shifts
      },
      itemDayShift (value) {
        this.$set(this, 'itemDayShifts', value)
      },
    },
    async created () {
      this.timestamps = JSON.parse(JSON.parse(localStorage.getItem('itemShift')).timestamps)
      this.getInputDate()
      await this.callApi()

      const params = {
        isFixed: true,
      }
      if (this.periodShift.customerId) {
        params.id = this.periodShift.customerId
        params.includeAll = true
      }
      this.$store.dispatch('job/getJobsCustomer', params)
      this.$store.dispatch('customer/getCustomer', params)
    },
    mounted () {
      moment.locale('ja')
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
        if (this.$route.query.updateSuccess) this.showSnackBar('messages.updateSuccess')
      }
      const titleHeaders = this.getHeaderTitle()
      this.headers = [...this.headersFix, ...titleHeaders]
      const now = moment().format('MM')
      const currentDate = moment(this.timestamps[0]).format('MM')
      this.optionYear = now === currentDate ? this.getWeekNumberInMonth() : 1
    },
    methods: {
      getWeekNumberInMonth (date) {
        const dayNumber = date ? moment(date).format('D') : moment().format('D')
        return Math.ceil(parseInt(dayNumber, 10) / 7)
      },
      getInputDate () {
        this.timestamps = JSON.parse(JSON.parse(localStorage.getItem('itemShift')).timestamps)
        this.endDateNumber = moment(this.timestamps[0]).daysInMonth()
        this.itemsyear = [1, 2, 3, 4].map(i => {
          return {
            text: `第${i}週（${(i - 1) * 7 + 1}〜${7 * i}日）`,
            value: i,
            startAt: (i - 1) * 7 + 1,
            endAt: 7 * i,
          }
        })
        if (this.endDateNumber > 28) {
          this.itemsyear.push({
            text: `第5週（29〜${this.endDateNumber}日）`,
            value: 5,
            startAt: 29,
            endAt: this.endDateNumber,
          })
        }
      },
      getHeaderTitle () {
        var curr = new Date(this.timestamps[0])
        if (this.optionChart === this.$t('chart.weekly')) {
          this.headerNumber = this.optionYear === 5 ? this.endDateNumber - 28 : 7
        }
        const headers = []
        var next = new Date(curr.getTime())
        const number = this.optionChart === this.$t('chart.monthly') ? 1 : this.optionYear
        if (this.optionChart !== this.$t('chart.daily')) {
          for (var i = (number - 1) * 7 + 1; i <= (number - 1) * 7 + this.headerNumber; i++) {
            next.setDate(i)
            if (i <= this.endDateNumber) {
              const header = {
                text: i + ' - ' + moment(next).format('ddd'),
                align: 'center',
                value: 'day_' + i,
                date: moment(next).format('YYYY年MM月DD日 (ddd)'),
                class: this.getClassHeader(next),
                sortable: false,
                width: '185px',
                typeTotal: false,
                dateFormat: moment(next).format('YYYY-MM-DD'),
              }
              headers.push(header)
            }
          }
        } else {
          const header = {
            text: moment(this.daily).format('DD-ddd'),
            align: 'center',
            value: 'day_' + moment(this.daily).format('DD'),
            date: moment(this.daily).format('YYYY年MM月DD日 (ddd)'),
            class: this.getClassHeader(this.daily),
            sortable: false,
            width: '185px',
            typeTotal: false,
            dateFormat: this.daily,
          }
          headers.push(header)
        }
        return headers
      },
      labelTime (data) {
        if (data.timeStart && data.timeEnd) {
          return data.timeStart + ' ~ ' + data.timeEnd
        }
      },
      onSearch () {},
      changeJob () {
        this.callApi()
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      addClassCurrent (index) {
        const now = moment().format('YYYY-MM-DD')
        return now === this.headers[index + 3].dateFormat ? 'color-current-date' : ''
      },
      openDialog (item, i) {
        this.dialog = true
        this.titleDate = this.headers[i + 3].date
        this.workDate = this.headers[i + 3].dateFormat
        this.itemsRow = item
        const data = item.listFixedShift[this.headers[i + 3].dateFormat]
        if (data.length > 0) {
          this.itemsRowSelected = data[0]
          this.itemJobPopup = data[0] && data[0].shift.jobId
          this.itemShiftPopup = data[0] && data[0].shiftId
        } else {
          this.itemsRowSelected = {}
          this.itemJobPopup = null
          this.itemShiftPopup = null
        }
        this.isEdit = data.length > 0
      },
      closePopup () {
        this.$refs.observer && this.$refs.observer.reset()
        this.dialog = false
        this.dialogGroup = false
      },
      onSubmit () {
        this.isEdit ? this.updateShift() : this.createShift()
      },
      getClassHeader (current) {
        const className = 'pr-0 pl-0 py-2 font-weight-bold '
        const now = moment().format('MMMM/YY/DD')
        const currentDate = moment(current).format('MMMM/YY/DD')
        return currentDate === now ? className + ' color-current-date' : className
      },
      async openDialogGroup (header, i) {
        if (i < 4) return
        this.dialogGroup = true
        const params = {
          periodShiftId: this.$route.params.id,
          date: header.dateFormat,
        }
        if (this.itemJob) {
          params.jobId = this.itemJob
        }
        if (this.search.trim()) {
          params.keyword = this.search.trim()
        }
        await this.$store.dispatch('fixedShift/getShiftInDay', params)
      },

      async onChangeType (value) {
        const month = moment(this.timestamps[0]).format('YYYY-MM-DD')
        const now = moment().format('MM')
        const currentDate = moment(this.timestamps[0]).format('MM')
        switch (value) {
          case this.$t('chart.monthly'):
            this.headerNumber = this.endDateNumber
            break
          case this.$t('chart.weekly'):
            this.optionYear = now === currentDate ? this.getWeekNumberInMonth() : 1
            break
          case this.$t('chart.daily'):
            this.headerNumber = 1
            this.daily = now === currentDate ? moment().format('YYYY-MM-DD') : month
            break
          default:
            this.headerNumber = 7
            break
        }
        await this.callApi()
      },
      onChangeYear (value) {
        this.optionYear = value
        this.callApi()
      },
      async callApi () {
        const params = {
          periodShiftId: this.$route.params.id,
        }
        await this.$store.dispatch('fixedShift/getListError', params)
        const month = moment(this.timestamps[0]).format('YYYY-MM')
        if (this.optionChart === this.$t('chart.weekly')) {
          params.startAt = month + '-' + this.itemsyear[this.optionYear - 1].startAt
          params.endAt = month + '-' + this.itemsyear[this.optionYear - 1].endAt
        } else if (this.optionChart === this.$t('chart.daily')) {
          params.startAt = this.daily
          params.endAt = this.daily
        } else {
          params.startAt = moment(this.timestamps[0]).format('YYYY-MM-DD')
          params.endAt = moment(this.timestamps[1]).format('YYYY-MM-DD')
        }
        if (this.itemJob) {
          params.jobId = this.itemJob
        }
        if (this.search.trim()) {
          params.keyword = this.search.trim()
        }
        await this.$store.dispatch('fixedShift/getListDetailShift', params)
      },
      textJob (data) {
        if (data && data[0] && data[0].shift) {
          return data[0].shift.job.name
        }
        return ''
      },
      textShift (data) {
        if (data && data[0] && data[0].shift) {
          const value = data[0].shift.shiftStartAt + ' ~ ' + data[0].shift.shiftEndAt
          const text = data[0].planBreakTime ? ' ( ' + moment.utc((data[0].planBreakTime * 60).toFixed(0) * 60 * 1000).format('HH:mm') + ' )' : ''
          return value + text
        }
        return ''
      },
      textWork (data) {
        if (data && data[0] && data[0].shift) {
          const min = moment(data[0].plannedEndAt).diff(moment(data[0].plannedStartAt), 'hours', true)
          const minue = data[0].planBreakTime ? (min - data[0].planBreakTime) : min
          var myStartDate = moment.utc(minue * 3600 * 1000).format('HH:mm')
          return '( ' + myStartDate + ' )'
        }
        return ''
      },
      async deteleItemAR (item) {
        const isDeleted = await this.$store.dispatch('fixedShift/deleteShift', item)
        if (isDeleted) {
          this.closePopup()
          await this.callApi()
        }
      },
      choosenJob (val) {
        this.itemJobPopup = val
      },
      choosenShift (val) {
        this.itemShiftPopup = val
      },
      async createShift () {
        const params = {
          parttimeEmployeeId: this.itemsRow.id,
          jobId: this.itemJobPopup,
          shiftId: this.itemShiftPopup,
          workdate: this.workDate,
          periodShiftId: this.periodShift.id,
        }
        const isCreated = await this.$store.dispatch('fixedShift/CreateShift', params)
        if (isCreated) {
          this.closePopup()
          await this.callApi()
        }
      },
      async updateShift () {
        const params = {
          id: this.itemsRowSelected.id,
          jobId: this.itemJobPopup,
          shiftId: this.itemShiftPopup,
        }
        const isUpdated = await this.$store.dispatch('fixedShift/UpdateShift', params)
        if (isUpdated) {
          this.closePopup()
          await this.callApi()
        }
      },
      openPopupError () {
        this.dialogError = true
      },
      closePopupError () {
        this.dialogError = false
      },
      async saveDay (date) {
        this.daily = date
        this.$refs.menu.save(date)
        const titleHeaders = this.getHeaderTitle()
        this.headers = [...this.headersFix, ...titleHeaders]
        await this.callApi()
      },
      async exportCsv () {
        this.headerExport = {
          'No.': 'No',
          管理番号: {
            field: 'manageId',
            callback: (value) => {
              return `${value.manageId}\n${value.sex ? '男' : '女'}`
            },
          },
          名前: {
            field: 'name',
            callback: (value) => {
              return `${value.normalName}\n${value.furiganaName}`
            },
          },
          国籍: 'language',
          工場: 'customerName',
          ジョブ: 'jobName',
          シフト: 'shiftTime',
          休憩: 'planBreakTime',
        }
        const params = {
          date: this.daily,
          periodShiftId: this.$route.params.id,
        }
        try {
          const response = await this.$store.dispatch('fixedShift/exportFixedShift', params)
          return response
        } catch (error) {
          this.showSnackBar(error)
        }
      },
    },
  }
</script>
<style lang="css" scoped>
.v-data-table {
  max-width: 100%;
}
.colorActive {
  color: #1bc4bd;
}
.infoWarp {
  padding-top: 20px
}
.lblText {
  margin-top: 10px
}
.v-chip-shift {
  border-radius: 10px;
  align-items: center;
  display: inline-flex;
  height: 100%;
  width: 100%;
  background: #C5DCFA80;
  margin: 15px 10px;
}
.color_work {
  color: #fa8745
}

.color_red {
  color: #E43E08
}
.item_job {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
::v-deep .color-current-date {
  background-color:#B6EDFF;
  opacity : 78%;
}
.borderDialog {
  margin-top: 24px;
  border: 1px solid #25ACD8;
  border-radius: 5px;
  padding: 10px;
}

tbody tr td {
  cursor: pointer;
}
.title-total-user , .title-group-job {
  color: #B5B5C3;
  size: 20px;
  font-weight: 600;
}
.item-date, .item-total-user {
  color:#464E5F;
  font-weight: 600;
}
</style>
